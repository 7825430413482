@font-face {
    font-family: 'Geometria';
    src: url('fonts/Geometria.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-Bold';
    src: url('fonts/Geometria-Bold.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-BoldItalic';
    src: url('fonts/Geometria-BoldItalic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-ExtaBlack';
    src: url('fonts/Geometria-ExtaBlack.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-ExtraBlackItalic';
    src: url('fonts/Geometria-ExtraBlackItalic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-ExtraLight';
    src: url('fonts/Geometria-ExtraLight.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-ExtraLightItalic';
    src: url('fonts/Geometria-ExtraLightItalic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-Heavy';
    src: url('fonts/Geometria-Heavy.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-HeavyItalic';
    src: url('fonts/Geometria-HeavyItalic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-Italic';
    src: url('fonts/Geometria-Italic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-Light';
    src: url('fonts/Geometria-Light.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-LightItalic';
    src: url('fonts/Geometria-LightItalic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-Medium';
    src: url('fonts/Geometria-Medium.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-MediumItalic';
    src: url('fonts/Geometria-MediumItalic.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-Thin';
    src: url('fonts/Geometria-Thin.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria-ThinItalic';
    src: url('fonts/Geometria-ThinItalic.otf');
    font-weight: normal;
    font-style: normal;
}