html, body{
	width: 100%;
	height: 100%;
}

body{
	line-height: 1.42;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: auto;
}

.wrapper{
	position: relative;
	// min-height: 100%;
	height: 100%;
	overflow: hidden;
}

.wrapper.mobile {
	min-height: 100%;
	height: auto;
}

.maincontent{
	height: 100%;
	// min-height: 650px;
	// transform: translate3d(0,0,0);
	// transition: 1s;

}


.container{
	width: 1200px;
	margin: 0 auto;
}

section{
	min-height: 100vh;
	//min-height: 650px;
	// height: 100%;
}

h1, h2, h3, h4, h5{
	margin-top: 0;
	font-weight: normal;
}
ul {
	padding: 0;
	margin: 0;

	li{
		list-style: none;
	}
}

p{
	padding: 0;
	margin: 0;
}

a{
	text-decoration: none;
}

// .footer{
// 	min-width: 960px;
// }

*{
	box-sizing: border-box;
}