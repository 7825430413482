$green:  rgba(35, 184, 140, 255);
$grey: rgba(53, 62, 72, 255);


@import "misc/normalize.css";
@import "misc/mixins.scss";
@import "fonts.scss";
@import "misc/adjustment.scss";
@import "main.scss";
@import "onepage-scroll.scss";
@import "form.scss";