.section__title {
	font-size: 25px;
	color: $grey;
	display: flex;
	margin-top: 30px;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	text-align: center;
	font-family: 'Geometria';

	&:after {
		display: block;
		position: absolute;
		content: "";
		width: 106px;
		height: 4px;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 26px;
		background: url("../img/divider.png") no-repeat;
		background-size: cover;
	}
}


////////////  Intro page    /////////////

section.section.intro {
	background: url("../img/bg-intro.png") no-repeat;
	background-size: cover;
	position: relative;
	font-family: 'Geometria';

	span.bold {
		font-family: 'Geometria-Bold';
	}
}

header.header {
    padding: 35px 0 30px;
    background-color: #fff;

    .container {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	}
} 

.header__col {
	position: relative;
}

.intro .safe-icon {
	width: 153px;
	height: 37px;
	// background: url("../img/logo-light.png") no-repeat;
	background: url("../img/logo.png") no-repeat;
	background-size: cover;
}

.lang__item {
	display: none;

	.lang__link {
		display: flex;
		align-items: center;
	    cursor: pointer;
	}

	&.active-lang {
		display: inline-block;
		order: -1;
	}
}

.lang__list.active {
	position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    border: 1px solid #82939c;
    border-radius: 10px;
    // padding: 0 20px 15px;
    background-color: #fff;
    transition: .3s;

    .lang__item {
    	display: inline-block;
	    // padding: 17px 30px;

	    &:hover {
	    	background: #f2f2f2;
	    }
    }

    .lang__item.active-lang {
    	border-radius: 10px 10px 0 0 ;
    }

    // .lang__item:last-child {
    // 	border-radius: 0 0 10px 10px;
    // }



	.lang__link {
		display: flex;
		padding: 17px 30px;
	}
}

.lang__icon {
	width: 28px;
	height: 28px;
}

.lang__name {
	padding-left: 11px;
	color: rgba(50, 41, 41, 255);
	font-family: 'Geometria';
}

#eng .lang__icon{

	background: url("../img/eng-flag.png") no-repeat;
	background-size: cover;
}

#ru .lang__icon{

	background: url("../img/rus-flag.png") no-repeat;
	background-size: cover;
}

#chi .lang__icon{

	background: url("../img/chinese-flag.png") no-repeat;
	background-size: cover;
}

#de .lang__icon{

	background: url("../img/Deutsch-eng.png") no-repeat;
	background-size: cover;
}

.intro__info {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.intro__left {
	flex-basis: 380px;
    padding-top: 30px;
    position: relative;
}

.intro__right {
    flex-basis: 700px;
}

.intro__img {
    width: 90%;     // возможно придётся вернуть
    height: 100%;
}

.intro__title {
	font-size: 42px;
	color: rgba(50, 41, 41, 255);
	display: inline;

	&:after {
		display: none;
	}

	.bold {
		font-weight: bold;
	}
}

.download {
    margin-top: 30px;   	
}

.distributive__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .distributive__item {
    	width: 58px;
    	height: 58px;
    	border-radius: 30px;
    	border: 2px solid #fff;
    	opacity: 0.7;
    	color: #fff;
    	transition: .4s;

    	// &:hover {
    	// 	opacity: 1;
    	// }

    	.download__link {
    		position: relative;
    		display: inline-block;
		    width: 100%;
		    height: 100%;
		    border-radius: 30px;
    	}

    	.download__link-inner {
    		position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%,-50%);
    		display: flex;
		    align-items: center;
    	}

    	&.done {
    		opacity: 1;
    	}

    	&.distributive-active {
    		width: 204px;

    		.download__text {
    			// display: inline-block;
			    padding-left: 32px;
			    font-family: 'Geometria';
    		}
    	}
    }

    .download__text {
    	// display: none;
    	color: #fff;
    }

    .distributive__image {
    	vertical-align: middle;
    }
}

.help-project {
	position: absolute;
    bottom: 45px;
	cursor: pointer;
}

.help-project-inner {
    display: flex;
	align-items: center;
}

.help__icon {
	width: 40px;
	height: 40px;
	background: url("../img/bitcoin-logo.png") no-repeat;
	background-size: cover;
}

.help__text {
	color: #fff;
	margin-left: 16px;
	font-family: 'Geometria';
}

///////////    second page /////////////

.statistic__description {
	margin-top: 52px;
    padding: 0 200px;
	font-size: 16px;
	color: $grey;
	text-align: center;
	font-family: 'Geometria';
}

.statistic__info {
	margin-top: 66px;
}

.count__list {
	display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .count__desc {
    	margin-top: 25px;
    	font-family: 'Geometria';
    }

    .counter {
	    margin-top: 29px;
    }
}

.count__item {
	text-align: center;

	.counter__digit {
	    vertical-align: middle;
	    font-family: 'Geometria';
	    font-size: 22px;
	}
}

.counter__item {
    position: relative;
	display: inline-block;
	width: 31px;
    height: 43px;
    background-color: #b9c2c7;
    border-radius: 10px;

    .counter__digit {
    	position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    color: $grey;
    }
}

.count__divider .count-divider {
	margin-left: 40px;
	margin-right: 40px;
	margin-top: 25%;
}

.os {
    margin-top: 30px;
}

.os__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .os__item {
    	transition: .3s;
		opacity: 0.7;

		&:hover {
			opacity: 1;
		}
    }

    .os__item.develop-done {
    	opacity: 1;
    }

    .os__link {
    	display: flex;
	    align-items: center;

    }

    .os__text {
    	margin-left: 25px;
    	color: $green;
    	font-size: 18px;
    	font-family: 'Geometria';
    }
}


///////  slide 3  ///////////

section.about {
	background: url("../img/bg-list3.png") no-repeat;
	background-size: cover;
	position: relative;
}

.about__title, .faq__title, .features__title {
	margin-top: 0;
	padding-top: 30px;
}

.about__text {
	color: $grey;
	padding: 0 60px;
	margin-top: 150px;
	font-family: 'Geometria';
}

.about__description_bold {
	// font-weight: bold;
	font-family: 'Geometria-Bold';
}


/////////   features         ///////////

section.features {
	background: url("../img/bg3.png") no-repeat;
	background-size: cover;
	position: relative;
}

.features__info {
	margin-top: 52px;
}

.feature__list {
	display: flex;
	flex-wrap: wrap;
}

.feature__item {
	flex-basis: 50%;
	text-align: center;
	margin-bottom: 30px;

	.feature__icon {
		height: 100px;
	}

	.feature__title {
		margin-top: 20px;
		color: $green;
		font-size: 18px;
		font-family: 'Geometria';
	}

	.feature__desc {
		margin-top: 10px;
		font-family: 'Geometria';
		font-size: 14px;
	}
}

.feature__desc {
    // width: 285px;
    margin: 0 auto;
    padding: 0 90px;
}

/////////////    faq           ////////////

section.faq {
	background-color: #e3eaf0;
	position: relative;
    margin-bottom: -20px;
    font-family: 'Geometria';
    font-size: 14px;
}

.img-block {
	text-align: center;
	margin-top: 100px;
}

.faq__info {
	text-align: center;
}

.faq__info-title {
	color: $green; 
}

.faq__info-text {
	color: $grey;
}

.faq__link {
	color: #6dbce5;
}

.faq__info-paragraf {
	margin-bottom: 20px;
	padding: 0 190px;
}

footer.footer {
	background-color: #fff;
    padding: 15px 0;
    position: absolute;
    bottom: 0;
    width: 100%;

	.container {
		display: flex;
		flex-wrap: wrap;
	}
}

.footer__col {
	flex: 1 1;
	display: flex;
    align-items: center;
}

.social__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .social__item {
    	padding-left: 50px;
    }

    .social__title {
    	margin-left: 10px;
    	color: rgba(50, 41, 41, 255);
    }

    .social__link {
    	display: flex;
    	align-items: center;
    }

    .social__icon {
	    width: 26px;
	    height: 26px;
	    border-radius: 50%;
	    position: relative;

	    svg {
	    	width: 15px;
		    fill: #fff;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%,-50%);
	    }
    }
}

.safe-icon {
	width: 139px;
	height: 34px;
	background: url("../img/logo.png") no-repeat;
	background-size: contain;
}

.footer__text {
	margin-left: 25px;
	font-size: 14px;
	color: rgba(106, 121, 128, 255);
}

.social__item_twitter .social__icon {
	background-color: #1DA1F2;
}

//////////    modal contact      ///////////

.modal-contact-over {
	position: fixed;
	top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.5);
    display: none;
    z-index: 10;
}

.modal-contact-over.active {
	display: block;
}

.modal-contact-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 43px 35px 32px;
    width: 460px;
    font-family: 'Geometria';

    .sent-text {
    	display: none;
    }

    &.sent {
    	.sent-text {
	    	display: block;
	    }
    	.contact__title, .contact-body, .send-row {
    		display: none;
    	}
    }
}

.contact-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact__title, .row__title {
	color: rgba(106, 121, 128, 255);
    padding-bottom: 10px;
}

.contact__title, .sent-text {
	font-size: 18px;
}

.row__title {
	font-size: 16px;
}

.contact-body {
    margin-top: 20px;
}

.contact-row {
	margin-bottom: 23px;
}

.row__input {
    width: 100%;
    border: 2px solid #e3eaf0;
    border-radius: 5px;
    padding: 13px 16px;
    color: rgba(106, 121, 128, 255);

	&::-moz-placeholder {
		color: rgba(185, 194, 199, 255);
	}

	&::-webkit-input-placeholder {
		color: rgba(185, 194, 199, 255);
	}
}

.row__textarea {
	height: 130px;
	resize: none;
}

.send-row {
	margin-top: 19px;
	text-align: center;
}

.send-btn {
	display: inline-block;
    padding: 20px 60px;
    background-color: $green;
    border-radius: 30px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.contact__close {
	width: 21px;
	height: 21px;
	background: url("../img/close.png") no-repeat;
	background-size: cover;
	cursor: pointer;
}


.modal__copy {
	position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 10;

    &.active {
    	display: block;
    }

    .modal-block {
    	position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
        background-color: #fff;
	    border-radius: 5px;
	    display: flex;
        width: 280px;
	    padding: 20px 10px;
	    justify-content: space-evenly;
	    align-items: center;
    }
}

.row__input.errored {
	border-color: red;
}

////////////      nav      ///////////////////

nav.nav {
	position: fixed;
    right: 40px;
    top: 50%;
}

.dot__anchor {
	display: block;
	width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 11px;
}

// @media screen and (min-width: 1600px) and (max-height: 775px){
// 	section.faq {
// 		margin-bottom: 0;
// 	}

// 	footer.footer {
// 		position: relative;
// 	}
// }

@media screen and (min-width: 1400px) {
	.section.intro {
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	}

	.intro__info {
	    flex-grow: 1;
	}

	.intro__img {
	    position: relative;
	    top: 50%;
	    transform: translateY(-50%);
	    width: auto;
	    height: auto;
	}

	.intro__left {
	    display: flex;
	    flex-direction: column;
	    justify-content: space-around;

	    .help-project {
	    	position: static;
	    }
	}

	.container {
		width: calc(100% - 100px);
		font-size: 1.5em;
	}

	.section.statistic .container, .section.about .container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.section.features .container {
		height: 100%;
	}

	.section.faq .container {
		height: 90%;
	}

	.faq__info {
		margin-top: 3%;
	}

	.features__info {
	    position: relative;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    margin-top: 0;
	}

	.about__text {
		position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    margin-top: 0;
	}

	.os {
		margin-bottom: 30px;
	}
	.faq .footer .container {
		flex-direction: row;
	}
}

@media screen and (min-width: 1600px) and (min-height: 920px) {
	.section.faq .container {
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	}

	.faq__info {
		margin-top: 0;
		margin-bottom: 5%;
	}

	.faq .footer .container {
		flex-direction: row;
	}

	.img-block {
		margin-top: 0;
	}
}

@media screen and (min-width: 1400px) and (max-height: 880px) {
	.intro__right .intro__img {
		width: 100%;
	}
}

@media screen and (min-width: 1400px) and (max-height: 770px) {
	.intro__right .intro__img {
		width: 100%;
	}

	.main-faq-container {
		display: flex;
	    flex-direction: column;
	}

	.section.faq .img-block {
	    display: none;
	}
}

@media screen and (min-width: 1400px) and (max-height: 650px) {
	.section.faq .img-block {
	    display: block;
	}

	.features__info {
		top: auto;
	    left: auto;
	    transform: none;
	    margin-top: 52px;
	}

	.about__text {
		position: static;
		transform: none;
		margin-top: 40px;
	}

	section.faq {
		margin-bottom: 0;
	}

	footer.footer {
		position: static;
	}
}

@media screen and (min-device-width: 1400px) and (min-height: 650px) and (orientation: landscape) { 
	.section.features .container {
		position: absolute;
	    left: 50%;
	    transform: translateX(-50%);
	    display: flex;
	    flex-direction: column;
	}

	.features__info {
	    position: static;
	    transform: none;
	    margin-top: 40px;
	    flex-grow: 1;
	    display: flex;
	}
}



@media screen and (max-width: 1200px) {
	.container {
		width: 940px;
	}

	.intro__info {
		text-align: center;
	}

	.about__text {
		margin-top: 52px;
	}
}



@media screen and (max-width: 1100px) {

	.distributive__list{
		justify-content: space-evenly;

		.distributive__item:nth-child(2){
			margin: 0 -40px;
		}
	}

	.distributive__list .download__text {
		display: none;
	}

	// .distributive__list .distributive__item.distributive-active .download__text {
	// 	display: none;
	// }

	.distributive__list .distributive__item.distributive-active {
	    width: 58px;
	}

	.help-project {
		position: static;
	    margin-top: 50px;

	    .help-project-inner {
	    	justify-content: center;
	    }
	}
}

@media screen and (max-width: 1024px) and (max-height: 1370px) {
	section {
		position: relative;
	}
	
	.section.statistic .container {
		position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    height: 100%;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
        padding-bottom: 20px;
	}

}

@media screen and (max-height: 650px) and (orientation: landscape) {
	
	.about__text {
	    padding-bottom: 20px;
	}
	section.faq {
		margin-bottom: 0;
	}
	// footer.footer {
	// 	position: static;
	// }
}

@media screen and (max-width: 940px) and (orientation: landscape) {
	.section.statistic .container {
		position: static;
		transform: none;
	}
}

@media screen and (max-width: 940px) {
	.container {
		// width: 720px;
		width: calc(100% - 20px);
	}

	.statistic__description {
		padding: 0 100px;
	}

	.faq__info-paragraf {
	    padding: 0;
	}

	.social__list .social__item {
		padding-left: 20px;
	}

	.safe-icon {
		background-size: contain;
	}
}



@media screen and (max-width: 720px) {
	// .container {
	// 	width: 540px;
	// }

	.section.statistic .container {
		width: 540px;
		position: static;
		transform: none;
	}

	.statistic__description {
		padding: 0 20px;
	}

	.count__item {
	    flex-basis: 100%;
        margin-bottom: 30px;

	    &.count__divider {
	    	display: none;
	    }
	}

	.os__list .os__item {
		flex-basis: 100%;

	    .os__link {
			justify-content: center;
		    padding: 20px 0;
		    border-top: 1px solid $green;
		    font-family: 'Geometria';
	    }
	}

	.section.about .container {
		width: 100%;
	}

	.feature__list {
		justify-content: center;
	}

	.feature__item {
		flex-basis: 100%;
	}
}

@media screen and (max-width: 540px) {
	.container, .section.statistic .container {
		width: 350px;
	}

	.section__title {
		font-size: 30px;
	}

	.intro__img {
		width: 100%;
	}

	.lang__name {
		display: none;
	}

	.lang__name {
		padding: 0;
	}

	.statistic__info {
		margin-top: 37px;
	}

	.count__item {
		margin-bottom: 0px;

		&:first-child {
			margin-bottom: 60px;
		}
	}

	.os {
		margin-top: 52px;
	}

	.about__text {
	    margin-top: 91px;
		// padding: 0;
        padding-bottom: 88px;
	}

	.features__info {
		margin-top: 91px;
		padding: 0 24px
	}

	.feature__item {
		margin-bottom: 62px;
	}

	.feature__item .feature__title {
		margin-top: 25px;
	}

	.feature__desc {
		padding: 0;
	}

	.footer__col {
		flex-direction: column;
	    flex-basis: 100%;
	}

	.social__list {
		flex-direction: column;
	}

	.footer__text {
		margin-top: 15px;
	}

	.social__list .social__item {
	    margin-top: 30px;
	    padding-left: 0;
	}

	.img-block {
		margin-top: 107px;
	}

	.faq__info {
	    margin-top: 35px;
	    padding: 0 21px;
	}

	footer.footer {
		position: static;
		margin-top: 50px;

		.container {
			margin: 32px auto 41px;
		}

		.footer__col:first-child {
			margin-bottom: 30px;
		}

		.footer__text {
			margin-left: 0;
		}
	}

	.modal-contact-inner {
		width: 90%;
	    padding: 20px 16px 32px;
	}

	.contact-body {
		margin-top: 12px;
	}
}

@media screen and (max-width: 350px) {
	.container, .section.statistic .container {
		width: 320px;
	}

	.header .container {
		padding: 0 20px;
	}

	.section__title.intro__title {
		font-size: 34px;
	}



	.about__text {
	    padding: 0 20px;
        padding-bottom: 88px;
	}

	.lang__name {
		display: none;
	}
}