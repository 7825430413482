body, html {
  margin: 0;
  overflow: hidden;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  transition: opacity 400ms;
}

body, .onepage-wrapper, html {
  display: block;
  position: static;
  padding: 0;
  width: 100%;
  height: 100%;
}

.onepage-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  padding: 0;
  -webkit-transform-style: preserve-3d;
}

.onepage-wrapper .section {
  width: 100%;
  height: 100%;
}

.onepage-pagination {
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 5;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: .3s;
}

.onepage-pagination li a{
  display: block;
  width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 11px;
    opacity: .5;
    border: 1px solid #232121;
    transition: .3s;
  
}

.onepage-pagination:nth-child(1) li.active {
  a {
    background-color: #fff;
  }
}

.onepage-pagination {

  li.active a {
    background-color: $green;
    opacity: 1;
    border: none;
  }
}


// .onepage-pagination.pagination_light {
//   li a {
//     opacity: .5;
//   }

//   li.active a {
//     background-color: $green;
//     opacity: 1;
//   }
// }

.onepage-pagination.pagination_green {
  li a {
    // opacity: .5;
    // background-color: grey;
    background-color: #fff;
    border: 1px solid #232121;
  }

  li.active a {
    background-color: $green;
    border: none;
  }
}


// .onepage-pagination li a:before{
//   content: '';
//   position: absolute;
//   width: 4px;
//   height: 4px;
//   background: rgba(0,0,0,0.85);
//   border-radius: 10px;
//   -webkit-border-radius: 10px;
//   -moz-border-radius: 10px;
// }

// .onepage-pagination li a.active:before{
//   width: 10px;
//   height: 10px;
//   background: none;
//   border: 1px solid black;
//   margin-top: -4px;
//   left: 8px;
// }

.disabled-onepage-scroll, .disabled-onepage-scroll .wrapper {
  overflow: auto;
}

.disabled-onepage-scroll .onepage-wrapper .section {
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
.disabled-onepage-scroll .onepage-wrapper {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  transform: none !important;
  -ms-transform: none !important;
  min-height: 100%;
}


.disabled-onepage-scroll .onepage-pagination {
  display: none;
}

body.disabled-onepage-scroll, .disabled-onepage-scroll .onepage-wrapper, html {
  position: inherit;
}